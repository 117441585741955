import { Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AbstractCustomTreeAction, CustomTreeNode, CustomTreeRootNode, CUSTOM_TREE_DATASOURCE_TOKEN, CUSTOM_TREE_NODE_TOKEN } from './custom-tree-shared.types';
import { CustomTreeNodeInsertComponentType } from './inserts/basic-custom-tree-node.component';
import { SimpleCustomTreeNodeInsertComponent } from './inserts/simple-custom-tree-node-insert/simple-custom-tree-node-insert.component';

export class CustomTreeDataSource<T = any> {

  private _markForChangeSubject = new Subject<void>();
  private _rootNodeBehaviorSubject = new BehaviorSubject<CustomTreeRootNode<T>>(null);
  private _componentInjectorBehaviorSubject = new BehaviorSubject<Injector>(null);
  private _actionsBehaviorSubject = new BehaviorSubject<AbstractCustomTreeAction>(null);

  private _defaultComponent = (SimpleCustomTreeNodeInsertComponent as unknown as CustomTreeNodeInsertComponentType<T>);

  get markForChange(): Observable<void> {
    return this._markForChangeSubject.asObservable();
  }

  get actions$(): Observable<AbstractCustomTreeAction> {
    return this._actionsBehaviorSubject.asObservable();
  }

  get rootNode(): CustomTreeRootNode<T> {
    return this._rootNodeBehaviorSubject.value;
  }

  set rootNode(value: CustomTreeRootNode<T>) {

    this._componentInjectorBehaviorSubject.pipe(filter(_ => !!_), take(1)).subscribe(injector => {

      const recFn = (node: CustomTreeNode<T>, parent: CustomTreeNode<T> | CustomTreeRootNode<T>) => {

        node.__parent = parent;
        node.component = node.component || this._defaultComponent || (SimpleCustomTreeNodeInsertComponent as unknown as CustomTreeNodeInsertComponentType<T>);

        node.__injector = Injector.create({
          providers: [
            {provide: CUSTOM_TREE_DATASOURCE_TOKEN, useValue: this},
            {provide: CUSTOM_TREE_NODE_TOKEN, useValue: node}
          ],
          name: 'CustomTreeNode',
          parent: injector
        });

        node?.children?.forEach(child => recFn(child, node));
      };

      value?.roots?.forEach(root => recFn(root, value));

    });

    this._rootNodeBehaviorSubject.next(value);
  }

  constructor(rootNode: CustomTreeRootNode<T>) {
    if (rootNode) {
      this.rootNode = rootNode;
    }
  }

  initComponentInjector(injector: Injector) {
    this._componentInjectorBehaviorSubject.next(injector);
  }

  triggerMarkForChange() {
    this._markForChangeSubject.next();
  }

  setDefaultComponent(component: CustomTreeNodeInsertComponentType) {
    this._defaultComponent = component;
  }

  dispatchAction(action: AbstractCustomTreeAction) {

    if (action?.type === 'toggle' && action.data) {
      (action.data as CustomTreeNode).collapsed = !(action.data as CustomTreeNode)?.collapsed;
    }

    this._actionsBehaviorSubject.next(action);
  }

  registerActionType(...types: string[]) {
    return this.actions$.pipe(filter(action => types?.includes(action?.type)));
  }

  /**
   * hides all nodes, which cannot be found with a search term by a given search function
   * (default search function, searches if the term exists (case sensitive) in the node's label)
   * @returns number of hidden nodes
   */
  searchNodes<T = any>(str: string, searchFunction?: (term: string, node: CustomTreeNode<T>) => boolean): number {

    const defaultSearchFunction = (term: string, node: CustomTreeNode): boolean => {
      return (term && node?.label) ? node?.label?.toLocaleLowerCase().includes?.(term?.toLowerCase()) : true;
    };

    searchFunction = searchFunction || defaultSearchFunction;

    let i = 0;

    const rec = (nodes: CustomTreeNode[]) => {
      nodes?.forEach(node => {
        const found = searchFunction(str, node);
        if (!found) {
          i++;
        }
        node.hidden = !found;
        if (node.children) {
          rec(node.children);
        }
      });
    };

    if (this.rootNode?.roots) {
      rec(this.rootNode.roots);
      this.triggerMarkForChange();
    }

    return i;

  }

}
