import { Component, OnInit } from '@angular/core';
import { ClickCustomTreeAction, ToggleCustomTreeAction } from '../../custom-tree-shared.types';
import { BasicCustomTreeNodeInsertComponent } from '../basic-custom-tree-node.component';

@Component({
  selector: 'app-simple-custom-tree-node-insert',
  templateUrl: './simple-custom-tree-node-insert.component.html',
  styleUrls: ['./simple-custom-tree-node-insert.component.scss']
})
export class SimpleCustomTreeNodeInsertComponent<T = any> extends BasicCustomTreeNodeInsertComponent<T> implements OnInit {

  get label() {
    return this.node?.label || this.node?.value || '';
  }

  get toggable() {
    return (typeof this.node?.toggable === 'boolean') ? this.node?.toggable : this.node?.children?.length > 0;
  }

  ngOnInit(): void {
  }

  toggle() {
    this.dataSourceRef.dispatchAction(ToggleCustomTreeAction.create(this.node));
  }

  clickOnNode() {
    this.dataSourceRef.dispatchAction(ClickCustomTreeAction.create(this.node));
  }

}
