import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CheckboxGroupDataSource } from '../../../checkbox-button-group/checkbox-group-data-source';
import { CheckboxGroupCustomTreeNodeData, CheckboxSelectionChangeCustomTreeAction } from '../../custom-tree-shared.types';
import { BasicCustomTreeNodeInsertComponent } from '../basic-custom-tree-node.component';




@Component({
  selector: 'app-checkbox-group-custom-tree-node-insert',
  templateUrl: './checkbox-group-custom-tree-node-insert.component.html',
  styleUrls: ['./checkbox-group-custom-tree-node-insert.component.scss']
})
export class CheckboxGroupCustomTreeNodeInsertComponent extends BasicCustomTreeNodeInsertComponent<CheckboxGroupCustomTreeNodeData> implements OnInit, OnDestroy {

  changeSubscription: Subscription;

  get checkBoxGroupDataSource(): CheckboxGroupDataSource<any> {
    return this.data?.dataSource;
  }

  set checkBoxGroupDataSource(value: CheckboxGroupDataSource<any>) {
    if (this.data) {
      this.data.dataSource = value;
    }
  }

  get isRadioButtonGroup() {
    return this.data?.isRadioButtonGroup;
  }

  ngOnInit(): void {

    if (!this.checkBoxGroupDataSource) {
      this.checkBoxGroupDataSource = new CheckboxGroupDataSource(this.data.items);
    }

    this.checkBoxGroupDataSource.isRadioButtonGroup = this.data.isRadioButtonGroup;
    this.changeSubscription?.unsubscribe();
    this.changeSubscription =
    this.checkBoxGroupDataSource.selectedItems$.subscribe(selectedItems => {
      this.dataSourceRef.dispatchAction(CheckboxSelectionChangeCustomTreeAction.create({node: this.node, selectedItems}));
    });
  }

  ngOnDestroy(): void {
    this.changeSubscription?.unsubscribe;
  }

}
