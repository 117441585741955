import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomTreeComponent } from './custom-tree.component';
import { SimpleCustomTreeNodeInsertComponent } from './inserts/simple-custom-tree-node-insert/simple-custom-tree-node-insert.component';
import { IconModule } from '../icon/icon.module';
import { ActivateElementModule } from '../../directives/activate-element/activate-tab-element.module';
import { CheckboxGroupCustomTreeNodeInsertComponent } from './inserts/checkbox-group-custom-tree-node-insert/checkbox-group-custom-tree-node-insert.component';
import { CheckboxGroupModule } from '../checkbox-button-group/checkbox-group.module';


@NgModule({
  declarations: [
    CustomTreeComponent,
    SimpleCustomTreeNodeInsertComponent,
    CheckboxGroupCustomTreeNodeInsertComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    ActivateElementModule,
    CheckboxGroupModule
  ],
  exports: [
    CustomTreeComponent
  ]
})
export class CustomTreeModule { }
