

<ng-template #abstractNode let-node="node" let-parent="parent" let-depth="depth">

  <div [ngClass]="['custom-tree-node', 'custom-tree-node-depth-' + depth]">

    <div [ngClass]="['custom-tree-node-insert', 'custom-tree-node-insert-depth-' + depth]">
      <ng-container [ngComponentOutlet]="node.component" [ngComponentOutletInjector]="node.__injector"></ng-container>
    </div>

    <div [ngClass]="['custom-tree-node-children', 'custom-tree-node-children-depth-' + depth]">

      <div class="custom-tree-node-collapsible-wrapper {{(node.collapsed ? 'custom-tree-node-collapsed' : 'custom-tree-node-extended')}}">
        <div class="custom-tree-node-collapsible">
          <ng-container *ngFor="let child of getVisibleChildren(node)">
            <ng-container *ngTemplateOutlet="abstractNode; context: {
              node: child,
              parent: node,
              depth: (depth + 1)
            }"></ng-container>
          </ng-container>
        </div>
      </div>

    </div>
  </div>

</ng-template>



<ng-container *ngIf="dataSource$ | async as ds">

  <ng-container *ngFor="let node of ds.rootNode?.roots">
    <ng-container *ngTemplateOutlet="abstractNode; context: {
      node: node,
      parent: (ds.rootNode?.roots),
      depth: 0
    }"></ng-container>
  </ng-container>

</ng-container>
