import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { CustomTreeDataSource } from './custom-tree-data-source';
import { AbstractCustomTreeAction, CustomTreeNode } from './custom-tree-shared.types';

@Component({
  selector: 'app-custom-tree',
  templateUrl: './custom-tree.component.html',
  styleUrls: ['./custom-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTreeComponent implements OnInit, OnDestroy {

  private _markForChangeSubscription = new Subscription();

  private _dataSourceBehaviorSubject = new BehaviorSubject<CustomTreeDataSource>(null);

  private _actionBehaviorSubject = new BehaviorSubject<any>(null);

  get dataSource$(): Observable<CustomTreeDataSource> {
    return this._dataSourceBehaviorSubject.asObservable();
  }

  get dataSource(): CustomTreeDataSource {
    return this._dataSourceBehaviorSubject.value;
  }

  @Input()
  set dataSource(value: CustomTreeDataSource) {
    value?.initComponentInjector(this.injector);

    this._markForChangeSubscription?.unsubscribe();
    this._markForChangeSubscription = value?.markForChange.subscribe(() => this.cdr.markForCheck());

    this._dataSourceBehaviorSubject.next(value);
  }

  constructor(private readonly injector: Injector, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._markForChangeSubscription?.unsubscribe();
  }

  getVisibleChildren(parent: CustomTreeNode): CustomTreeNode[] {
    return parent?.children?.filter?.(child => !child.hidden) || [];
  }

}
