import { InjectionToken, Injector } from '@angular/core';
import type { CheckboxGroupDataSource, CheckboxItem } from '../checkbox-button-group/checkbox-group-data-source';
import type { CustomTreeDataSource } from './custom-tree-data-source';
import type { CustomTreeNodeInsertComponentType } from './inserts/basic-custom-tree-node.component';


// eslint-disable-next-line
export const CUSTOM_TREE_DATASOURCE_TOKEN = new InjectionToken<CustomTreeDataSource>('CustomTreeDataSource-Token');

// eslint-disable-next-line
export const CUSTOM_TREE_NODE_TOKEN = new InjectionToken<CustomTreeNode>('CustomTreeNode-Token');


export interface CustomTreeRootNode<T = any> {
  roots: CustomTreeNode<T>[];
  __parentInjector?: Injector;
}

export interface CustomTreeNode<T = any> {
  component?: CustomTreeNodeInsertComponentType<T>;
  value: string;
  label?: string;
  hidden?: boolean;
  toggable?: boolean;
  collapsed?: boolean;
  data?: T;
  children?: CustomTreeNode<T>[];
  __parent?: CustomTreeNode<T> | CustomTreeRootNode<T>;
  __injector?: Injector;
}

export enum BasicCustomTreeAction {
  TOGGLE = 'toggle',
  CLICK = 'click',
  CHECKBOX_SELECTION_CHANGE = 'checkboxSelectionChange'
}

export abstract class AbstractCustomTreeAction<D = any> {
  static create: (...args: any[]) => AbstractCustomTreeAction;
  protected constructor(public type: string, public data?: D) {}
}

export class NodeCustomTreeAction extends AbstractCustomTreeAction<CustomTreeNode> {

  static override create(type: string, node: CustomTreeNode): NodeCustomTreeAction {
    return new NodeCustomTreeAction(type, node);
  }

}

export class ToggleCustomTreeAction extends AbstractCustomTreeAction<CustomTreeNode> {

  static override create(node: CustomTreeNode): ToggleCustomTreeAction {
    return new ToggleCustomTreeAction(BasicCustomTreeAction.TOGGLE, node);
  }

}

export class ClickCustomTreeAction extends AbstractCustomTreeAction<CustomTreeNode> {

  static override create(node: CustomTreeNode): ClickCustomTreeAction {
    return new ClickCustomTreeAction(BasicCustomTreeAction.CLICK, node);
  }

}

export interface CheckboxGroupCustomTreeNodeData {
  dataSource?: CheckboxGroupDataSource;
  items: CheckboxItem<string>[];
  isRadioButtonGroup?: boolean;
}


export interface CheckboxSelectionChangeCustomTreeActionData {
  selectedItems: CheckboxItem<string>[];
  node: CustomTreeNode;
}


export class CheckboxSelectionChangeCustomTreeAction extends AbstractCustomTreeAction<CheckboxSelectionChangeCustomTreeActionData> {
  static override create(data: CheckboxSelectionChangeCustomTreeActionData) {
    return new CheckboxSelectionChangeCustomTreeAction(BasicCustomTreeAction.CHECKBOX_SELECTION_CHANGE, data);
  }
}
