import { Component, Injector, Optional } from '@angular/core';
import { CustomTreeDataSource } from '../custom-tree-data-source';
import { CustomTreeNode, CUSTOM_TREE_DATASOURCE_TOKEN, CUSTOM_TREE_NODE_TOKEN } from '../custom-tree-shared.types';


export type CustomTreeNodeInsertComponentType<T = any> = new (...args: any[]) => BasicCustomTreeNodeInsertComponent<T>;


@Component({
  template: ''
})
export class BasicCustomTreeNodeInsertComponent<T = any> {

  private _node: CustomTreeNode<T>;
  private _dataSourceRef: CustomTreeDataSource<T>;

  get dataSourceRef(): CustomTreeDataSource<T> {
    return this._dataSourceRef;
  }

  get node(): CustomTreeNode<T> {
    return this._node;
  }

  get data(): T {
    return this.node.data;
  }

  constructor(@Optional() injector: Injector) {
      try {
          this._node = injector.get(CUSTOM_TREE_NODE_TOKEN);
          this._dataSourceRef = injector.get(CUSTOM_TREE_DATASOURCE_TOKEN);
      } catch (_) {
          // ignore exception
      }
  }

}
