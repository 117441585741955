
<app-icon
  *ngIf="toggable"
  tabindex="0"
  iconSize="EXTRA_SMALL"
  [ngClass]="['node-toggle-button', 'node-toggle-button-' + (node.collapsed ? 'collapsed' : 'expanded')]"
  [iconType]="'CARET_DOWN'"
  (activateElement)="toggle()"
></app-icon>
<app-icon
  *ngIf="!toggable"
  iconSize="EXTRA_SMALL"
  class="none-toggle-button"
></app-icon>
<div (activateElement)="clickOnNode()">{{label}}</div>
